// Theme
$light-bg: #FFFFFF;
$dark-bg: #111111;
$primary-bg: #243B7F;
$sub-bg: #F3F3F3;

$text-dark: #111111;
$text-light: #FFFFFF;
$text-primary: #243B7F;
$text-red: #EB2026;
$text-green: #37B34A;
$soft-blue: #E9EBF7;

$button: (
  'c1': #243B7F,
  'c2': #6074AF,
);

// responsive
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  x-xxl: 1600px,
);

@mixin media-breakpoint-down($size) {
  @media screen and (min-width: map-get($grid-breakpoints, $size)) {
    @content;
  }
}
