.row {
  margin-left: -15px;
  margin-right: -15px;

  & > div {
    padding: 0 15px;
  }

  @include media-breakpoint-down(lg) {
    margin-left: -10px;
    margin-right: -10px;

    & > div {
      padding: 0 10px;
    }
  }
}

.mr-10{
  margin-right: 10px !important;
}

.mr-15{
  margin-right: 15px !important;
}

.mx-auto{
  margin: auto;
}

.mt-5{
  margin-top: 5px !important;
}

.mt-25{
  margin-top: 25px !important;
}

.mt-30{
  margin-top: 30px !important;
}

.mb-15{
  margin-bottom: 15px !important;
}

.mb-30{
  margin-bottom: 30px !important;
}