@import "~src/assets/styles/variables";

.btn{
  position: relative;
  font-size: 18px;
  font-weight: 600;
  border-radius: 99px;
  padding: 12px 25px;
  display: flex;
  align-items: center;
  z-index: 1;
  overflow: hidden;
  background: $sub-bg;
  color: $text-primary;
  transition: 0.25s all ease-in-out;

  @include media-breakpoint-down(xl) {
    font-size: 20px;
    padding: 14px 30px;
  }

  &.btn-with-link{
    padding: 0 !important;

    a{
      width: 100%;
      border-radius: 99px;
      padding: 12px 25px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-down(xl) {
        padding: 14px 30px;
      }
    }
  }

  &.btn-sm{
    font-size: 16px;
    padding: 8px 20px;
    width: 100%;
    justify-content: center;

    img{
      height: 16px;
      margin-right: 12px;
    }

    &:hover{
      background: darken($sub-bg, 5%);
    }

    @include media-breakpoint-down(lg) {
      width: fit-content;
    }

     &.btn-with-link{
       a{
         padding: 8px 20px;
       }
     }
  }
  
  a{
    color: $text-light;

    &:hover, &:active{
      text-decoration: none;
    }

    &.primary{
      color: $text-primary;
    }
  }

  &:focus{
    box-shadow: none;
    outline: none;
  }
}

.btn-start {
  background: linear-gradient(
    90deg,
    map-get($button, "c1") 0%,
    map-get($button, "c2") 100%
  );
  color: $text-light;

  &::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      map-get($button, "c1") 0%,
      map-get($button, "c1") 100%
    );
    border-radius: 99px;
    z-index: -1;
    transition: 0.25s all ease-in-out;
    opacity: 0;
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }

  img{
    height: 18px;
    margin-left: 40px;
  }
}

// TEXT
.t{
  padding-left: 50px;
}