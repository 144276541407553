body,
html {
  padding: 0;
  margin: 0;
  font-family: "Kanit", sans-serif;
  font-weight: normal;
  width: 100%;
  font-size: 16px;
  line-height: 1;
  scroll-behavior: smooth;
  background-color: #FFFFFF;
  color: #111111;
}
@media screen and (min-width: 992px) {
  body,
html {
    font-size: 16px;
  }
}

*:focus {
  outline: none;
}

a:hover {
  cursor: pointer;
}

@media screen and (min-width: 1600px) {
  .container {
    max-width: 1400px;
  }
}

.hr {
  width: 250px;
  height: 6px;
  border-radius: 99px;
  background: linear-gradient(90deg, #243B7F 0%, #6074AF 50%, #243B7F 100%);
  margin: auto;
  margin-bottom: 30px;
}
@media screen and (min-width: 992px) {
  .hr {
    height: 10px;
  }
}
.hr.invert {
  background: #FFFFFF;
}

.csm-text {
  font-weight: 700;
  margin-bottom: 10px;
}
.csm-text.hidden-mobile {
  display: none;
}
@media screen and (min-width: 992px) {
  .csm-text.hidden-mobile {
    display: block;
  }
}
.csm-text.hidden-desktop {
  display: block;
}
@media screen and (min-width: 992px) {
  .csm-text.hidden-desktop {
    display: none;
  }
}

section.home {
  overflow: hidden;
}
section.home .hero-bg {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: url(~src/assets/images/background.png) center center/cover no-repeat;
}
@media screen and (min-width: 1200px) {
  section.home .hero-bg {
    padding-top: 140px;
  }
}
section.home .hero-bg .full-height {
  display: flex;
  flex-grow: 1;
}
section.home .hero-bg .hero-banner {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 992px) {
  section.home .hero-bg .hero-banner {
    flex-direction: row;
  }
}
section.home .hero-bg .hero-banner .hero-banner-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 35px 0 40px 0;
}
@media screen and (min-width: 992px) {
  section.home .hero-bg .hero-banner .hero-banner-text {
    margin: 0px;
  }
}
section.home .hero-bg .hero-banner .hero-banner-text .hero-text {
  font-size: 38px;
  font-weight: 700;
  color: #243B7F;
  margin-bottom: 15px;
  text-align: center;
}
section.home .hero-bg .hero-banner .hero-banner-text .hero-text .tr {
  color: #EB2026;
}
section.home .hero-bg .hero-banner .hero-banner-text .hero-text .tg {
  color: #37B34A;
}
@media screen and (min-width: 992px) {
  section.home .hero-bg .hero-banner .hero-banner-text .hero-text {
    text-align: left;
    font-size: 45px;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 1200px) {
  section.home .hero-bg .hero-banner .hero-banner-text .hero-text {
    font-size: 50px;
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 1600px) {
  section.home .hero-bg .hero-banner .hero-banner-text .hero-text {
    font-size: 65px;
  }
}
section.home .hero-bg .hero-banner .hero-banner-text .hero-sub-text {
  font-size: 20px;
  color: #243B7F;
  line-height: 1.5;
  margin-bottom: 35px;
  text-align: center;
}
@media screen and (min-width: 992px) {
  section.home .hero-bg .hero-banner .hero-banner-text .hero-sub-text {
    text-align: left;
    font-size: 25px;
  }
}
@media screen and (min-width: 1600px) {
  section.home .hero-bg .hero-banner .hero-banner-text .hero-sub-text {
    font-size: 30px;
    margin-bottom: 50px;
  }
}
section.home .hero-bg .hero-banner .hero-banner-text .btn-start {
  margin: 0 auto 0 auto;
  width: fit-content;
}
@media screen and (min-width: 992px) {
  section.home .hero-bg .hero-banner .hero-banner-text .btn-start {
    margin: 0 0 30px 0;
  }
}
@media screen and (min-width: 1200px) {
  section.home .hero-bg .hero-banner .hero-banner-text .btn-start {
    margin: 0 0 40px 0;
  }
}
section.home .hero-bg .hero-banner .hero-banner-text .app-store {
  margin-bottom: 0px;
  justify-content: center;
  display: flex;
  display: none;
}
@media screen and (min-width: 992px) {
  section.home .hero-bg .hero-banner .hero-banner-text .app-store {
    justify-content: flex-start;
    margin-bottom: 50px;
  }
}
@media screen and (min-width: 1200px) {
  section.home .hero-bg .hero-banner .hero-banner-text .app-store {
    margin-bottom: 80px;
  }
}
section.home .hero-bg .hero-banner .hero-banner-text .app-store img {
  height: 40px;
}
section.home .hero-bg .hero-banner .hero-banner-text .app-store img.cms {
  opacity: 1;
}
@media screen and (min-width: 992px) {
  section.home .hero-bg .hero-banner .hero-banner-text .app-store img {
    height: 50px;
  }
}
@media screen and (min-width: 992px) {
  section.home .hero-bg .hero-banner .hero-banner-text .app-store {
    display: flex;
  }
}
section.home .hero-bg .hero-banner .hero-banner-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
}
@media screen and (min-width: 992px) {
  section.home .hero-bg .hero-banner .hero-banner-img {
    margin-bottom: 0px;
  }
}
section.home .hero-bg .hero-banner .hero-banner-img img.mockup {
  height: auto;
  width: 100%;
  margin-right: 0;
  transform: scale(1.15);
  margin-left: -10px;
}
@media screen and (min-width: 992px) {
  section.home .hero-bg .hero-banner .hero-banner-img img.mockup {
    transform: scale(1);
    height: 540px;
    width: auto;
    margin-right: -15px;
  }
}
@media screen and (min-width: 1200px) {
  section.home .hero-bg .hero-banner .hero-banner-img img.mockup {
    height: 660px;
    margin-right: -20px;
  }
}
@media screen and (min-width: 1600px) {
  section.home .hero-bg .hero-banner .hero-banner-img img.mockup {
    height: 930px;
    margin-right: -70px;
  }
}
section.home .hero-bg .hero-banner .hero-banner-img .app-store {
  margin-bottom: 0px;
  justify-content: center;
  display: flex;
  display: flex;
  margin: 15px 0;
}
@media screen and (min-width: 992px) {
  section.home .hero-bg .hero-banner .hero-banner-img .app-store {
    justify-content: flex-start;
    margin-bottom: 50px;
  }
}
@media screen and (min-width: 1200px) {
  section.home .hero-bg .hero-banner .hero-banner-img .app-store {
    margin-bottom: 80px;
  }
}
section.home .hero-bg .hero-banner .hero-banner-img .app-store img {
  height: 40px;
}
section.home .hero-bg .hero-banner .hero-banner-img .app-store img.cms {
  opacity: 1;
}
@media screen and (min-width: 992px) {
  section.home .hero-bg .hero-banner .hero-banner-img .app-store img {
    height: 50px;
  }
}
@media screen and (min-width: 992px) {
  section.home .hero-bg .hero-banner .hero-banner-img .app-store {
    display: none;
  }
}
section.home .about-wrapper {
  padding: 40px 0;
}
@media screen and (min-width: 992px) {
  section.home .about-wrapper {
    padding: 70px 0;
  }
}
section.home .about-wrapper .about-title {
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  margin-bottom: 15px;
}
@media screen and (min-width: 992px) {
  section.home .about-wrapper .about-title {
    font-size: 30px;
  }
}
@media screen and (min-width: 1200px) {
  section.home .about-wrapper .about-title {
    font-size: 40px;
  }
}
section.home .about-wrapper .about-desc {
  position: relative;
  z-index: 1;
  max-width: 1160px;
  margin: auto;
}
section.home .about-wrapper .about-desc p {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 50px;
}
@media screen and (min-width: 992px) {
  section.home .about-wrapper .about-desc p {
    font-size: 18px;
  }
}
@media screen and (min-width: 1200px) {
  section.home .about-wrapper .about-desc p {
    font-size: 20px;
  }
}
section.home .about-wrapper .about-desc .quote {
  position: absolute;
  z-index: -1;
  height: 70px;
}
section.home .about-wrapper .about-desc .quote.open {
  left: 0px;
  top: -20px;
}
section.home .about-wrapper .about-desc .quote.close {
  bottom: -20px;
  right: 0px;
}
@media screen and (min-width: 1200px) {
  section.home .about-wrapper .about-desc .quote {
    height: 120px;
  }
  section.home .about-wrapper .about-desc .quote.open {
    left: -50px;
    top: -50px;
  }
  section.home .about-wrapper .about-desc .quote.close {
    bottom: -50px;
    right: -50px;
  }
}
section.home .manual-wrapper {
  background-color: #F3F3F3;
  padding: 40px 0;
  text-align: center;
}
@media screen and (min-width: 992px) {
  section.home .manual-wrapper {
    padding: 70px 0;
  }
}
section.home .manual-wrapper h3 {
  font-weight: 700;
  font-size: 26px;
  margin-bottom: 40px;
}
section.home .manual-wrapper .manual-list {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@media screen and (min-width: 992px) {
  section.home .manual-wrapper .manual-list {
    flex-direction: row;
  }
}
section.home .manual-wrapper .manual-list .manual {
  padding: 20px;
  border-radius: 20px;
  background: #FFFFFF;
  box-shadow: 0px 10px 20px rgba(17, 17, 17, 0.1);
  width: auto;
  max-width: unset;
  margin: 0 15px;
}
section.home .manual-wrapper .manual-list .manual:not(:last-child) {
  margin-bottom: 25px;
}
@media screen and (min-width: 992px) {
  section.home .manual-wrapper .manual-list .manual {
    padding: 25px;
    width: 100%;
    max-width: 300px;
    margin: 0 15px;
  }
  section.home .manual-wrapper .manual-list .manual:not(:last-child) {
    margin-bottom: 0px;
  }
}
section.home .manual-wrapper .manual-list .manual .title-wrapper {
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
  margin-bottom: 15px;
}
@media screen and (min-width: 992px) {
  section.home .manual-wrapper .manual-list .manual .title-wrapper {
    flex-direction: column;
    text-align: center;
    margin-bottom: 0;
  }
}
section.home .manual-wrapper .manual-list .manual .title-wrapper .icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #E9EBF7;
  margin: 0 15px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
section.home .manual-wrapper .manual-list .manual .title-wrapper .icon img {
  max-height: 40px;
  max-width: 40px;
}
@media screen and (min-width: 992px) {
  section.home .manual-wrapper .manual-list .manual .title-wrapper .icon {
    margin: 0 auto 15px auto;
    width: 90px;
    height: 90px;
  }
  section.home .manual-wrapper .manual-list .manual .title-wrapper .icon img {
    max-height: 50px;
    max-width: 50px;
  }
}
section.home .manual-wrapper .manual-list .manual h5 {
  font-weight: 600;
  margin-bottom: 0;
}
@media screen and (min-width: 992px) {
  section.home .manual-wrapper .manual-list .manual h5 {
    margin-bottom: 15px;
  }
}
section.home .manual-wrapper .video-manual {
  margin: 30px 0 20px 0;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  border-radius: 15px;
  overflow: hidden;
}
@media screen and (min-width: 992px) {
  section.home .manual-wrapper .video-manual {
    margin: 70px 0 0 0;
  }
}
section.home .manual-wrapper .video-manual iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.anchor {
  position: relative;
  top: -80px;
  visibility: hidden;
}
@media screen and (min-width: 992px) {
  .anchor {
    top: -100px;
  }
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}
.row > div {
  padding: 0 15px;
}
@media screen and (min-width: 992px) {
  .row {
    margin-left: -10px;
    margin-right: -10px;
  }
  .row > div {
    padding: 0 10px;
  }
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mx-auto {
  margin: auto;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.btn {
  position: relative;
  font-size: 18px;
  font-weight: 600;
  border-radius: 99px;
  padding: 12px 25px;
  display: flex;
  align-items: center;
  z-index: 1;
  overflow: hidden;
  background: #F3F3F3;
  color: #243B7F;
  transition: 0.25s all ease-in-out;
}
@media screen and (min-width: 1200px) {
  .btn {
    font-size: 20px;
    padding: 14px 30px;
  }
}
.btn.btn-with-link {
  padding: 0 !important;
}
.btn.btn-with-link a {
  width: 100%;
  border-radius: 99px;
  padding: 12px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 1200px) {
  .btn.btn-with-link a {
    padding: 14px 30px;
  }
}
.btn.btn-sm {
  font-size: 16px;
  padding: 8px 20px;
  width: 100%;
  justify-content: center;
}
.btn.btn-sm img {
  height: 16px;
  margin-right: 12px;
}
.btn.btn-sm:hover {
  background: #e6e6e6;
}
@media screen and (min-width: 992px) {
  .btn.btn-sm {
    width: fit-content;
  }
}
.btn.btn-sm.btn-with-link a {
  padding: 8px 20px;
}
.btn a {
  color: #FFFFFF;
}
.btn a:hover, .btn a:active {
  text-decoration: none;
}
.btn a.primary {
  color: #243B7F;
}
.btn:focus {
  box-shadow: none;
  outline: none;
}

.btn-start {
  background: linear-gradient(90deg, #243B7F 0%, #6074AF 100%);
  color: #FFFFFF;
}
.btn-start::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #243B7F 0%, #243B7F 100%);
  border-radius: 99px;
  z-index: -1;
  transition: 0.25s all ease-in-out;
  opacity: 0;
}
.btn-start:hover::after {
  opacity: 1;
}
.btn-start img {
  height: 18px;
  margin-left: 40px;
}

.t {
  padding-left: 50px;
}