@import "~src/assets/styles/variables";

body,
html {
  padding: 0;
  margin: 0;
  font-family: 'Kanit', sans-serif;
  font-weight: normal;
  width: 100%;
  font-size: 16px;
  line-height: 1;
  scroll-behavior: smooth;
  background-color: $light-bg;
  color: $text-dark;

  @include media-breakpoint-down(lg) {
    font-size: 16px;
  }
}

* {
  &:focus {
    outline: none;
  }
}

a{
  &:hover{
    cursor: pointer;
  }
}

.container{
  @include media-breakpoint-down(x-xxl) {
    max-width: 1400px;
  }
}

.hr{
  width: 250px;
  height: 6px;
  border-radius: 99px;
  background: linear-gradient(
    90deg,
    map-get($button, "c1") 0%,
    map-get($button, "c2") 50%,
    map-get($button, "c1") 100%
  );
  margin: auto;
  margin-bottom: 30px;

  @include media-breakpoint-down(lg) {
    height: 10px;
  }

  &.invert{
    background: $light-bg;
  }
}

// MIXIN
@mixin app-store {
  margin-bottom: 0px;
  justify-content: center;
  display: flex;

  @include media-breakpoint-down(lg) {
    justify-content: flex-start;
    margin-bottom: 50px;
  }

  @include media-breakpoint-down(xl) {
    margin-bottom: 80px;
  }

  img{
    height: 40px;

    &.cms{
      opacity: 1;
    }

    @include media-breakpoint-down(lg) {
      height: 50px;
    }
  }

  // &:hover{
  //   cursor: pointer;
  // }
}

.csm-text{
  font-weight: 700;
  margin-bottom: 10px;

  &.hidden-mobile{
    display: none;

    @include media-breakpoint-down(lg) {
      display: block;
    }
  }

  &.hidden-desktop{
    display: block;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}

// HOME
section.home{
  overflow: hidden;

  .hero-bg{
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: url(~src/assets/images/background.png) center center/cover no-repeat;

    @include media-breakpoint-down(xl) {
      padding-top: 140px;
    }

    .full-height{
      display: flex;
      flex-grow: 1;
    }

    .hero-banner{
      display: flex;
      flex-direction: column;

      @include media-breakpoint-down(lg) {
        flex-direction: row;
      }

      .hero-banner-text{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 35px 0 40px 0;

        @include media-breakpoint-down(lg) {
          margin: 0px;
        }

        .hero-text{
          font-size: 38px;
          font-weight: 700;
          color: $text-primary;
          margin-bottom: 15px;
          text-align: center;

          .tr{
            color: $text-red;
          }

          .tg{
            color: $text-green;
          }

          @include media-breakpoint-down(lg) {
            text-align: left;
            font-size: 45px;
            margin-bottom: 20px;
          }

          @include media-breakpoint-down(xl) {
            font-size: 50px;
            margin-bottom: 30px;
          }

          @include media-breakpoint-down(x-xxl) {
            font-size: 65px;
          }
        }

        .hero-sub-text{
          font-size: 20px;
          color: $text-primary;
          line-height: 1.5;
          margin-bottom: 35px;
          text-align: center;

          @include media-breakpoint-down(lg) {
            text-align: left;
            font-size: 25px;
          }

          @include media-breakpoint-down(x-xxl) {
            font-size: 30px;
            margin-bottom: 50px;
          }
        }

        .btn-start{
          margin: 0 auto 0 auto;
          width: fit-content;

          @include media-breakpoint-down(lg) {
            margin: 0 0 30px 0;
          }

          @include media-breakpoint-down(xl) {
            margin: 0 0 40px 0;
          }
        }

        .app-store{
          @include app-store();
          display: none;

          @include media-breakpoint-down(lg) {
            display: flex;
          }
        }
      }

      .hero-banner-img{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 30px;

        @include media-breakpoint-down(lg) {
          margin-bottom: 0px;
        }

        img.mockup{
          height: auto;
          width: 100%;
          margin-right: 0;
          transform: scale(1.15);
          margin-left: -10px;

          @include media-breakpoint-down(lg) {
            transform: scale(1);
            height: 540px;
            width: auto;
            margin-right: -15px;
          }

          @include media-breakpoint-down(xl) {
            height: 660px;
            margin-right: -20px;
          }

          @include media-breakpoint-down(x-xxl) {
            height: 930px;
            margin-right: -70px;
          }
        }

        .app-store{
          @include app-store();
          display: flex;
          margin: 15px 0;

          @include media-breakpoint-down(lg) {
            display: none;
          }
        }
      }
    }
  }

  .about-wrapper{
    padding: 40px 0;

    @include media-breakpoint-down(lg) {
      padding: 70px 0;
    }

    .about-title{
      font-weight: 700;
      font-size: 24px;
      text-align: center;
      margin-bottom: 15px;

      @include media-breakpoint-down(lg) {
        font-size: 30px;
      }

      @include media-breakpoint-down(xl) {
        font-size: 40px;
      }
    }

    .about-desc{
      position: relative;
      z-index: 1;
      max-width: 1160px;
      margin: auto;

      p{
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        margin-bottom: 50px;

        @include media-breakpoint-down(lg) {
          font-size: 18px;
        }

        @include media-breakpoint-down(xl) {
          font-size: 20px;
        }
      }

      .quote{
        position: absolute;
        z-index: -1;
        height: 70px;

        &.open{
          left: 0px;
          top: -20px;
        }

        &.close{
          bottom: -20px;
          right: 0px;
        }

        @include media-breakpoint-down(xl) {
          height: 120px;

          &.open{
            left: -50px;
            top: -50px;
          }

          &.close{
            bottom: -50px;
            right: -50px;
          }
        }
      }
    }
  }

  .manual-wrapper{
    background-color: $sub-bg;
    padding: 40px 0;
    text-align: center;

    @include media-breakpoint-down(lg) {
      padding: 70px 0;
    }

    h3{
      font-weight: 700;
      font-size: 26px;
      margin-bottom: 40px;
    }

    .manual-list{
      display: flex;
      justify-content: center;
      flex-direction: column;

      @include media-breakpoint-down(lg) {
        flex-direction: row;
      }

      .manual{
        padding: 20px;
        border-radius: 20px;
        background: $light-bg;
        box-shadow: 0px 10px 20px rgba($dark-bg, 10%);
        width: auto;
        max-width: unset;
        margin: 0 15px;

        &:not(:last-child){
          margin-bottom: 25px;
        }

        @include media-breakpoint-down(lg) {
          padding: 25px;
          width: 100%;
          max-width: 300px;
          margin: 0 15px;

          &:not(:last-child){
            margin-bottom: 0px;
          }
        }

        .title-wrapper{
          display: flex;
          flex-direction: row;
          text-align: left;
          align-items: center;
          margin-bottom: 15px;

          @include media-breakpoint-down(lg) {
            flex-direction: column;
            text-align: center;
            margin-bottom: 0;
          }
          
          .icon{
            width: 70px;
            height: 70px;
            border-radius: 50%;
            background: $soft-blue;
            margin: 0 15px 0 0;
            display: flex;
            justify-content: center;
            align-items: center;

            img{
              max-height: 40px;
              max-width: 40px;
            }

            @include media-breakpoint-down(lg) {
              margin: 0 auto 15px auto;
              width: 90px;
              height: 90px;

              img{
                max-height: 50px;
                max-width: 50px;
              }
            }
          }
        }

        h5{
          font-weight: 600;
          margin-bottom: 0;

          @include media-breakpoint-down(lg) {
            margin-bottom: 15px;
          }
        }
      }
    }

    .video-manual{
      margin: 30px 0 20px 0;
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 25px;
      height: 0;
      border-radius: 15px;
      overflow: hidden;

      @include media-breakpoint-down(lg) {
        margin: 70px 0 0 0;
      }

      iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.anchor {
  position: relative;
  top: -80px;
  visibility: hidden;

  @include media-breakpoint-down(lg) {
    top: -100px;
  }
}